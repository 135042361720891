import React from 'react';
import './App.css';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import Home from './Pages/Home';
import Header from './Components/Layout/Header';
import LoginHeader from './Components/Layout/LoginHeader';
import Footer from './Components/Footer';
import SignInModal from './Components/UI/SignInModal';
import JoinNowModal from './Components/UI/JoinNowModal';
import PhotoUploadModal from './Components/UI/PhotoUploadModal';
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from './Store/Login-Slice';
import SharePhotoModal from './Components/UI/SharePhotoModal';
import { decryptUserData } from './utils/encryptedData'
import ReactGA from "react-ga"
import IntercomChat from './Components/Chat/IntercomChat';
import PopupModal from './Components/UI/PopupModal';
import ProfileImgUploadModal from './Components/UI/ProfileImgUploadModal';
import CustomLoader from './Components/Skeleton/CustomLoader';
import ProfilePage from './Pages/ProfilePage';
import UserFollowersAndFollowingPage from './Pages/UserFollowersAndFollowingPage';
import { SkeletonTheme } from 'react-loading-skeleton';
import amplitude from 'amplitude-js';
import ActivityPage from './Pages/ActivityPage';
import CoverPhotoUploadModal from './Components/UI/CoverPhotoUploadModal';
import { initializeSocket, emitNewUser, sendNotification } from './utils/Socket';
import QueuedPhotoEditModal from './Components/UI/QueuedPhotoEditModal';
import UploadPhotoFromGallery from './Components/UI/UploadPhotoFromGallery';
import { updateLastVisitTime } from './utils/API/LastTimeVisitApi';
import LightBoxModal from './Components/UI/LightBoxModal';
import PayWallModal from './Components/UI/PayWallModal';
import { ModalAction } from './Store/Modal-Slice';
import useWisePopsIntegration from './Hooks/useWisePopsIntegration';
const LazyGiveawayPage = React.lazy(() => import('./Pages/GiveawayPage'))
const LazyDuplicateGiveawayPage = React.lazy(() => import('./Pages/DuplicateGiveawayPage'))
const LazySharePhoto = React.lazy(() => import('./Pages/SharePhoto'))
const LazyHowWorkPage = React.lazy(() => import('./Pages/HowWorkPage'))
const LazyBetaPage = React.lazy(() => import('./Pages/BetaPage'))
const LazyEditProfilePage = React.lazy(() => import('./Pages/EditProfilePage'))
const LazyMeetTerryPage = React.lazy(() => import('./Pages/MeetTerryPage'))
const LazyPrivacyPolicyPage = React.lazy(() => import('./Pages/PrivacyPolicyPage'))
const LazyTermsAndConditionsPage = React.lazy(() => import('./Pages/TermsAndConditionsPage'))
const LazyContactPage = React.lazy(() => import('./Pages/ContactPage'))
const LazyAboutPage = React.lazy(() => import('./Pages/AboutPage'))
const LazyThirdlogoutPage = React.lazy(() => import('./Pages/ThirdLogoutPage'))
const LazyResetPasswordPage = React.lazy(() => import('./Pages/ResetPasswordPage'))
const LazyEditPhotoPage = React.lazy(() => import('./Pages/EditPhotoPage'))
const LazyFeedbackPage = React.lazy(() => import('./Pages/FeedbackPage'))
const LazySecondLogoutPage = React.lazy(() => import('./Pages/SecondLogoutPage'))
const LazyNewMembers = React.lazy(() => import('./Pages/NewMembersPage'))
const LazyTopTenPage = React.lazy(() => import('./Pages/TopTenPage'))
const LazyNewGiveawayPage = React.lazy(() => import('./Pages/NewGiveawayPage'))
const LazyPreviousGiveawayPage = React.lazy(() => import('./Pages/PreviousGiveawayPage'))
const LazyQueueManagerPage = React.lazy(() => import('./Pages/QueueManager'))
const LazyTownHallPage = React.lazy(() => import('./Pages/TownHallPage'))
const LazyTownHallSecondPage = React.lazy(() => import('./Pages/TownHallSecondPage'))
const LazyPrevMonthlyTownhallPage = React.lazy(() => import('./Pages/PrevMonthlyTownhallPage'))
const LazyClubPage = React.lazy(() => import('./Pages/ClubPage'))
const LazyOtherUsersProfile = React.lazy(() => import('./Pages/OtherUsersProfilePage'))
const LazyPayWallPage = React.lazy(() => import('./Pages/PayWallPage'))
const LazyPaywall14DaysFreeTrialPage = React.lazy(() => import('./Pages/Paywall14DaysFreeTrialPage'))
const LazyPaywall2MonthTrialPage = React.lazy(() => import('./Pages/Paywall2MonthTrialPage'))
const LazyLambertCoursePage = React.lazy(() => import('./Pages/LambertCoursePage'))
const LazyInviteFriendPage = React.lazy(() => import('./Pages/InviteFriendPage'))
const LazyHuntScorePage = React.lazy(() => import('./Pages/HuntScorePage'))
const LazyNewGiveawayPageWotantcraft = React.lazy(() => import('./Pages/NewGiveawayWotancraftPage'))


function App() {


  const [socket, setSocket] = useState(null);
  //  <---------------- decrypt data -------------> 
  const encryptedUserData = localStorage.getItem('Data');
  const decryptedUserData = decryptUserData(encryptedUserData);
  const loginUserId = decryptedUserData.userid
  let usertoken = decryptedUserData.token
  let storeUserLoggedInInformation = decryptedUserData.isLoggedIn


  const SessionValue = sessionStorage.getItem('bigphotohuntSession');
  // <------------------------------------> 
  document.addEventListener('contextmenu', function (e) {
    e.preventDefault();
  });
  //  <---------------- Google Analytics -------------> 
  const GoogleAnalyticsID = "G-XD16TFXGXR"
  ReactGA.initialize(GoogleAnalyticsID)
  // <------------------------------------> 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let modalName = searchParams.get('modal');
  //  <---------------- Amplitude -------------> 
  amplitude.getInstance().init('377019229287576f6434e90c6af77d4');
  // <------------------------------------> 


  // <--------------WisePops----------------------> 
  <useWisePopsIntegration />
  // <------------------------------------> 

  let dispatch = useDispatch()
  let isLogginState = useSelector(state => state.Login.isLoggedIn)
  let PopupState = useSelector(state => state.Modal.PopupModalState)
  let PhotoUploadState = useSelector(state => state.Modal.PhotoUploadModalState)
  let ProfileUploadState = useSelector(state => state.Modal.ProfileUploadModalState)
  let SharePhotoState = useSelector(state => state.Modal.SharePhotoModalState)
  let SignInModalState = useSelector(state => state.Modal.SignInModalState)
  let JoinNowModalState = useSelector(state => state.Modal.JoinNowModalState)
  let CoverPhotoUploadState = useSelector(state => state.Modal.CoverPhotoUploadModalState)
  // let QueueDModalState = useSelector(state => state.Modal.QueuedModalState)
  let UploadPhotoFromGalleryModalState = useSelector(state => state.Modal.UploadPhotoFromGalleryModalState)
  let isLightboxModalOpen = useSelector(state => state.Modal.isLightboxModalOpen)
  let isPayWallOpenState = useSelector(state => state.Modal.isPayWallOpen)
  // <------------------------------------> 
  useEffect(() => {

    if (storeUserLoggedInInformation == true) {
      dispatch(LoginAction.setIsLoggedIn(true))
    }

    if (modalName == "paywall") {

      dispatch(ModalAction.setisPayWallOpen(true))
    }

  }, [])

  // < --------Socket ------->

  useEffect(() => {
    const socketInstance = initializeSocket();
    setSocket(socketInstance);
  }, []);

  useEffect(() => {
    emitNewUser(socket, loginUserId);
  }, [socket, loginUserId]);

  let SocketReceiverUserid = useSelector(state => state.Socket.SocketReceiverUserID)
  let ReloadNotificationFn = useSelector(state => state.Socket.ReloadNotificationSenderFn)

  useEffect(() => {
    if (SocketReceiverUserid) {
      sendNotification(socket, loginUserId, SocketReceiverUserid);
    }
  }, [ReloadNotificationFn])

  // < --------amplitude------------>

  useEffect(() => {

    if (!SessionValue) {
      amplitude.getInstance().setUserId(loginUserId);
      amplitude.getInstance().logEvent('Session', { UserID: loginUserId });
      sessionStorage.setItem('bigphotohuntSession', true);

      const lastTimevisit = async () => {
        try {
          const response = await updateLastVisitTime(usertoken)
        } catch (error) {

        }
      };
      lastTimevisit();

    }
  }, []);

  // < -------------------->
  const user = storeUserLoggedInInformation
    ? {
      name: decryptedUserData.useDisplay_name,
      email: decryptedUserData.user_email,
      created_at: decryptedUserData.CreatedAt,
    }
    : null;


  const currentUrl = window.location.pathname;


  return (
    <>
      <SkeletonTheme baseColor="#ebebeb" highlightColor="#dad5d5">
        {SignInModalState && <SignInModal />}
        {JoinNowModalState && <JoinNowModal />}
        {PhotoUploadState && <PhotoUploadModal />}
        {ProfileUploadState && <ProfileImgUploadModal />}
        {CoverPhotoUploadState && <CoverPhotoUploadModal />}
        {SharePhotoState && <SharePhotoModal />}
        {PopupState && <PopupModal />}
        {!isLogginState && <Header />}
        {isLogginState && <LoginHeader socket={socket} />}
        {/* {QueueDModalState && <QueuedPhotoEditModal />} */}
        {UploadPhotoFromGalleryModalState && <UploadPhotoFromGallery usertoken={usertoken} />}
        {isLightboxModalOpen && <LightBoxModal />}
        {isPayWallOpenState && <PayWallModal />}



        {<IntercomChat user={user} />}


        <Routes>

          <Route path="/" element={<Home />} />

          <Route path="/B031124" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazySecondLogoutPage />
            </React.Suspense>
          } />

          <Route path="/C051324" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyThirdlogoutPage />
            </React.Suspense>
          } />



          <Route path="/profile" element={storeUserLoggedInInformation ? (<ProfilePage />) : <Navigate to='/' />} />



          <Route path="/otherusers-profile" element={storeUserLoggedInInformation ?
            (<React.Suspense fallback={<CustomLoader />}>
              <LazyOtherUsersProfile />
            </React.Suspense>)
            : <Navigate to='/' />}
          />

          <Route path="/pmupgrade" element={<React.Suspense fallback={<CustomLoader />}>
            <LazyPayWallPage />
          </React.Suspense>}
          />

          <Route path="/14dfspecial" element={<React.Suspense fallback={<CustomLoader />}>
            <LazyPaywall14DaysFreeTrialPage />
          </React.Suspense>}
          />
          <Route path="/2mfspecial" element={<React.Suspense fallback={<CustomLoader />}>
            <LazyPaywall2MonthTrialPage />
          </React.Suspense>}
          />


          <Route path="/queue" element={storeUserLoggedInInformation ?
            (<React.Suspense fallback={<CustomLoader />}>
              <LazyQueueManagerPage />
            </React.Suspense>)
            : <Navigate to='/' />}
          />

          <Route path="/topten" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyTopTenPage />
            </React.Suspense>
          } />





          <Route path="/Photo" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazySharePhoto />
            </React.Suspense>
          } />


          <Route path="/about"
            element={
              <React.Suspense fallback={<CustomLoader />}>
                <LazyAboutPage />
              </React.Suspense>
            } />

          <Route path="/contact"
            element={
              <React.Suspense fallback={<CustomLoader />}>
                <LazyContactPage />
              </React.Suspense>
            } />

          <Route path="/monthly-townhall-0824" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyTownHallPage usertoken={usertoken} />
            </React.Suspense>
          } />

          <Route path="/monthly-townhall" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyTownHallSecondPage usertoken={usertoken} />
            </React.Suspense>
          } />
          <Route path="/previous-monthly-townhall" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyPrevMonthlyTownhallPage usertoken={usertoken} />
            </React.Suspense>
          } />

          <Route path="/club-page" element={storeUserLoggedInInformation ?
            <React.Suspense fallback={<CustomLoader />}>
              <LazyClubPage />
            </React.Suspense>
            : <Navigate to='/' />
          } />

          <Route path="/beta" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyBetaPage />
            </React.Suspense>
          } />

          <Route path="/terms" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyTermsAndConditionsPage />
            </React.Suspense>
          } />

          <Route path="/policy" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyPrivacyPolicyPage />
            </React.Suspense>
          } />

          <Route path="/how-works" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyHowWorkPage />
            </React.Suspense>
          } />

          <Route path="/meet-terry" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyMeetTerryPage />
            </React.Suspense>
          } />

          <Route path="/feedback" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyFeedbackPage />
            </React.Suspense>
          } />


          <Route path="/new-members" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyNewMembers />
            </React.Suspense>
          } />

          <Route path="/edit-profile" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyEditProfilePage />
              </React.Suspense>
              : <Navigate to='/'
              />} />



          <Route path="/activity" element={storeUserLoggedInInformation ? (<ActivityPage />) : <Navigate to='/' />} />


          <Route path="giveaway" element={

            <React.Suspense fallback={<CustomLoader />}>
              <LazyGiveawayPage />
            </React.Suspense>

          } />
          <Route path="/giveaway/:id" element={

            <React.Suspense fallback={<CustomLoader />}>
              <LazyDuplicateGiveawayPage />
            </React.Suspense>

          } />


          <Route path="new-giveaway" element={

            <React.Suspense fallback={<CustomLoader />}>
              <LazyNewGiveawayPage usertoken={usertoken} />
            </React.Suspense>

          } />

          <Route path="/new-giveaway_wotancraft" element={

            <React.Suspense fallback={<CustomLoader />}>
              <LazyNewGiveawayPageWotantcraft usertoken={usertoken} />
            </React.Suspense>

          } />

          <Route path="previousgiveaways" element={

            <React.Suspense fallback={<CustomLoader />}>
              <LazyPreviousGiveawayPage usertoken={usertoken} />
            </React.Suspense>

          } />


          <Route path="/pierretlambert-course" element={<React.Suspense fallback={<CustomLoader />}>
            <LazyLambertCoursePage usertoken={usertoken} />
          </React.Suspense>}
          />



          <Route path="/people" element={<UserFollowersAndFollowingPage />} />

          <Route path="/reset-password" element={
            <React.Suspense fallback={<CustomLoader />}>
              <LazyResetPasswordPage />
            </React.Suspense>
          } />

          <Route path="/edit-photo" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyEditPhotoPage />
              </React.Suspense>
              : <Navigate to='/'
              />} />

          <Route path="/invite-friend" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyInviteFriendPage />
              </React.Suspense>
              : <Navigate to='/'
              />} />

          <Route path="/hunt-score" element={
            storeUserLoggedInInformation ?
              <React.Suspense fallback={<CustomLoader />}>
                <LazyHuntScorePage usertoken={usertoken} />
              </React.Suspense>
              : <Navigate to='/'
              />} />

        </Routes>


        {currentUrl !== '/C051324' && <Footer />}
      </SkeletonTheme>
    </>
  );
}

export default App;
